export default defineNuxtRouteMiddleware((to, _) => {
  const stSlugStr = to.params.stSlug

  if (!stSlugStr || Array.isArray(stSlugStr)) return
  const stSlug = parseInt(stSlugStr)
  if (!redirectJson[stSlug]) return

  return navigateTo(
    {
      path: to.path.replace(/(st-)([0-9]+)/g, `$1${redirectJson[stSlug]}`),
      query: to.query,
    },
    {
      redirectCode: 301,
    }
  )
})

const redirectJson: { [key: number]: number } = {
  9971705: 9970904, // 広島県 白鳥,
  3400102: 9961805, // 大阪府 中津,
  3400115: 3500131, // 兵庫県 春日野道,
  1160211: 3400807, // 大阪府 吹田,
  1160306: 3500117, // 兵庫県 西宮,
  1193005: 9992524, // 鹿児島県 谷山
}
